import { useMediaQuery, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { BannerContainer, BannerContent, BannerTitle, BannerDescription, BannerShopButton } from "../../styles/banner";
import { useNavigate } from "react-router-dom";
// Images

// Components

export default function Banner() {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();

    return (
        <Box>   
            <BannerContainer src={require('../../assests/banner.jpg')}>
                {/* <BannerImage src={'/images/banner/online_shopping.jpg'} /> */}
                <BannerContent>
                    <Typography variant="h6">
                        UNICORN SHOPPING MART
                    </Typography>
                    <BannerTitle variant="h2"> Unicorn </BannerTitle>
                    <BannerDescription variant="subtitle">
                        Welcome to Unicorn Shopping Mart, your one-stop destination for magical and enchanting products.
                        Discover a world of wonder with our unique selection of unicorn-inspired items and mystical treasures.
                    </BannerDescription>
                    <BannerShopButton color="primary" onClick={() => {
                        navigate('/products');
                    }}>Shop Now</BannerShopButton>
                </BannerContent>
            </BannerContainer>
        </Box>
    );
}