import { useTheme } from "@emotion/react";
import { Box, Dialog, DialogContent, DialogTitle, Slide, styled, useMediaQuery, IconButton, Typography } from "@mui/material";
import { Product, ProductImage } from "../../styles/product";
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from "../../styles/theme";
import IncDec from "../../ui";

function SlideTransition(props) {
    return <Slide direction="down" {...props} />;
}

const ProductDetailWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4],
}));

const ProductDetailInfoWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
}));

export default function ProductDetail({ product, open, onClose }) {

    const theme = useTheme();

    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            TransitionComponent={SlideTransition}
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
            fullScreen
        >
            <DialogTitle
                sx={{
                    backgroundColor: Colors.secondary,
                    color: 'white',
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    Product Detail
                    <IconButton onClick={onClose} sx={{ color: 'white' }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent>

                <ProductDetailWrapper flexDirection={matches ? 'column' : 'row'}>
                    <Product sx={{ mr: 4 }}>
                        <ProductImage src={product.image} alt={product.name} />
                    </Product>
                    <ProductDetailInfoWrapper>
                        <Typography variant="subtitle1" fontWeight="bold">
                            {product.name}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            {product.description}
                        </Typography>
                        <Typography variant="h6">
                            ${product.price}
                        </Typography>
                        <IncDec />
                    </ProductDetailInfoWrapper>
                </ProductDetailWrapper>
            </DialogContent>
        </Dialog>
    );
}