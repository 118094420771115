import { createContext, useState, useContext } from "react";

export const UiContext = createContext();

export const useUiContext = () => useContext(UiContext);

export const UiProvider = ({ children }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const toggleDrawer = () => { 
        setIsDrawerOpen(!isDrawerOpen);
    };

    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen);
    };

    return (
        <UiContext.Provider value={{ isDrawerOpen, toggleDrawer, isSearchOpen, toggleSearch }}>
            {children}
        </UiContext.Provider>
    );
};