import { ListItem, ListItemButton, ListItemIcon, Typography } from "@mui/material";
import { AppBarContainer, AppBarHeader, AppBarList } from "../../styles/appBar";
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
// Components
import Actions from "./action";

export default function AppBarDesktop({ matches }) {

    const navigate = useNavigate();
    return (
        <AppBarContainer>
            <img src={require('../../assests/unicor_logo.png')} width="100px" alt="UNICORN" />
            <AppBarHeader>
                UNICORN
            </AppBarHeader>
            <AppBarList type="row">
                <ListItem onClick={() => navigate('/')}>
                    Home
                </ListItem>
                <ListItem onClick={() => navigate('/about')}>
                    About
                </ListItem>
                <ListItem onClick={() => navigate('/products')}>
                    Products
                </ListItem>
                <ListItem onClick={() => navigate('/careers')}>
                    Careers
                </ListItem>
                <ListItem onClick={() => navigate('/contact')}>
                    Contact Us
                </ListItem>
                <ListItemButton
                    sx={{
                        width: '100%',
                    }}
                >
                    <ListItemIcon>
                        <SearchIcon />
                    </ListItemIcon>
                </ListItemButton>
            </AppBarList>
            <Actions matches={matches} />
        </AppBarContainer>
    );
}