import { useTheme } from "@mui/material/styles";
import { Box, Grid2, useMediaQuery } from "@mui/material";

// Data
import { ProductData } from "../../data";

// Components
import SingleProduct from "./singleProducts";
import SingleProductDesktop from "./sinfleProductDesktop";

export default function Products() {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const renderProducts = () => {
        return ProductData.map((product) => (
            <Grid2
                className="test"
                item
                xs={2}
                sm={4}
                md={4}
                lg={3}
                key={product.id}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                    margin: '10px',
                }}
            >
                {!matches ? <SingleProductDesktop product={product} matches={matches} /> : <SingleProduct product={product} matches={matches} />}
            </Grid2>
        ));
    }
    return (
        <Box>
            <Grid2
                container
                spacing={{ xs: 2, md: 3 }}
                justifyContent="center"
                sx={{
                    marginTop: '20px 4px 10px 4px',
                }}
                columns={{ xs: 4, sm: 8, md: 12 }}
            >
                {renderProducts()}
            </Grid2>
        </Box>
    );
}