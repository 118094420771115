import { useState, useEffect, useRef } from "react";
import { Box, Slide } from "@mui/material";

// Styles
import { PromotionContainer, MessageText } from "../../styles/promotion";

const Messages = [
    "Get 20% off on your first order!",
    "Welcome to the club!",
    "Thank you for supporting us!",
    "We love you!",
    "You are awesome!",
    "You are amazing!",
    "You are fantastic!",
];

export default function Promotion() {
    const containerRef = useRef(null);
    const [show, setShow] = useState(true);
    const [messageIndex, setMessageIndex] = useState(0);

    useEffect(() => {
        // setTimeout(() => {
        //     setShow(false);
        // }, 3000);

        const interval = setInterval(() => {

            setMessageIndex((prevIndex) => (prevIndex + 1) % Messages.length);

            setShow(true);

            // setTimeout(() => {
            //     setShow(false);
            // }, 3000);
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    return (
        <PromotionContainer ref={containerRef}>
            <Slide
                container={containerRef.current}
                direction={show ? "left" : "right"}
                in={show}
                timeout={{
                    enter: 500,
                    exit: 100,
                }}
            >
                <Box display="flex" alignItems="center" justifyContent="center">
                    <MessageText>
                        {Messages[messageIndex]}
                    </MessageText>
                </Box>
            </Slide>
        </PromotionContainer>
    );
}