import React from 'react';
import { Box, Typography, Container, List, ListItem, ListItemText, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const jobOpenings = [
    { title: 'Sales Manager', department: 'Sales' },
    { title: 'Assistant Sales Manager', department: 'Sales' },
    { title: 'Sales Executive', department: 'Sales' },
];

const Careers = () => {
    const navigate = useNavigate();

    const handleApply = () => {
        navigate('/contact', { state: { fromCareers: true } });
    };

    return (
        <Box>
            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Careers at Unicorn
                </Typography>
                <Typography variant="body1" paragraph>
                    Join our team and be part of something magical! At Unicorn, we're always looking for talented individuals who share our passion for innovation and creativity.
                </Typography>
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                    Current Job Openings
                </Typography>
                <List>
                    {jobOpenings.map((job, index) => (
                        <ListItem key={index} divider>
                            <ListItemText 
                                primary={job.title} 
                                secondary={`Department: ${job.department}`} 
                            />
                            <Button variant="contained" color="primary" onClick={handleApply}>
                                Apply
                            </Button>
                        </ListItem>
                    ))}
                </List>
                <Typography variant="body1" sx={{ mt: 4 }}>
                    Don't see a position that fits your skills? We're always interested in hearing from passionate individuals. Send us your resume through our Contact page.
                </Typography>
            </Container>
        </Box>
    );
};

export default Careers;
