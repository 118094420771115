
import { ThemeProvider } from '@emotion/react';
import { Box } from '@mui/material';

// Components
import AppBar from './components/appBar';
import Footer from './components/footer';
import AppDrawer from './components/drawer';

// Screens
import Home from './screen/Home';
import Login from './components/user/login';
import Products from './screen/Products';
import Careers from './screen/Careers';
import Contact from './screen/Contact';
import About from './screen/about';
// Styles
import { theme } from './styles/theme';
import { UiProvider } from './context/ui';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '100vh',
          // width: '100vw',
          background: '#fff',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <UiProvider>
          <BrowserRouter>
            <AppBar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/about" element={<About />} />
              <Route path="/products" element={<Products />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
            <Footer />
            <AppDrawer />
          </BrowserRouter>
        </UiProvider>
      </Box>
    </ThemeProvider>
  );
}

export default App;
