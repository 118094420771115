import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, TextField, Button, Snackbar } from '@mui/material';
import { useLocation } from 'react-router-dom';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [resume, setResume] = useState(false);
    const [message, setMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.fromCareers) {
            setMessage('I am interested in career opportunities at Unicorn.');
        } else {
            setMessage('');
        }
    }, [location]);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would typically send the form data to your backend
        console.log({ name, email, message });
        setOpenSnackbar(true);
        setName('');
        setEmail('');
        setMessage('');
    };

    return (
        <Box>
            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                    Have a question or want to get in touch? Fill out the form below and we'll get back to you as soon as possible.
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="phoneNumber"
                        label="Phone Number"
                        name="phoneNumber"
                        autoComplete="tel"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="message"
                        label="Message"
                        id="message"
                        multiline
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    {location.state?.fromCareers && (
                        <TextField
                            margin="normal"
                            fullWidth
                            id="resume"
                            name="resume"
                            type="file"
                            placeholder="Upload your resume"
                            inputProps={{ accept: '.pdf,.doc,.docx' }}
                            onChange={(e) => {
                                // Handle file upload logic here
                                console.log('File selected:', e.target.files[0]);
                            }}
                        />
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Send Message
                    </Button>
                </Box>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackbar(false)}
                    message="Message sent successfully!"
                />
            </Container>
        </Box>
    );
};

export default Contact;
