import { styled, Typography, TextField } from "@mui/material";
import { Colors } from "../theme";

export const FooterTitle = styled(Typography)(({ theme }) => ({
    textTransform: 'uppercase',
    marginBottom: '1em',
    fontWeight: 'bold',
}));

export const SubscribeTextField = styled(TextField)(({ theme }) => ({
    '.MuiInputLabel-root': {
        color: Colors.white,
    },
    '.MuiInput-root:before': {
        borderColor: Colors.white,
    },
    '.MuiInput-root:after': {
        borderColor: Colors.white,
    },
    '.MuiInput-root:hover': {
        borderColor: Colors.white,
    },
}));