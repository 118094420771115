import { Typography } from "@mui/material";
import { ProductMetaWrapper } from "../../styles/product";


export default function ProductMeta({ product, matches }) {
    return (
        <ProductMetaWrapper>
            <Typography variant={matches ? "h6" : "h5"} fontWeight="bold">
                {product.name}
            </Typography>
            <Typography variant={matches ? "caption" : "body1"} color="text.secondary">
                ${product.price}
            </Typography>
        </ProductMetaWrapper>
    );
}