import { useMediaQuery, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Components
import AppBarDesktop from './appBarDesktop';
import AppBarMobile from './appBarMobile';

export default function AppBar() {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box>
            {!matches ? <AppBarDesktop matches={matches} /> : <AppBarMobile matches={matches} />}
        </Box>
    );

}