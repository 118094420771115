import { useState } from "react";
import { Stack } from "@mui/material";

// Icons
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShareIcon from '@mui/icons-material/Share';
// Styles
import { Product, ProductActionButton, ProductActionWrapper, ProductAddToCart, ProductFavButton, ProductImage } from "../../styles/product";

// Components
import ProductMeta from "./productMeta";
import ProductDetail from "../productDetail";
// Hooks
import useDialogModal from "../../hooks/useDialogModal";

export default function SingleProductDesktop({ product, matches }) {

    const [showOptions, setShowOptions] = useState(false);
    const [ProductDetailDialog, showProductDetailDialog] = useDialogModal(ProductDetail);

    const handleMouseEnter = () => {
        setShowOptions(true);
    }

    const handleMouseLeave = () => {
        setShowOptions(false);
    }

    return (
        <>
            <Product onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <ProductImage src={product.image} alt={product.name} />
                <ProductFavButton isFav={1}>
                    <FavoriteBorderIcon />
                </ProductFavButton>
                {
                    showOptions && (
                        <ProductAddToCart onClick={showProductDetailDialog} variant="contained">Add to cart</ProductAddToCart>
                    )
                }
                <ProductActionWrapper show={showOptions}>
                    {/* <Stack direction="column">
                        <ProductFavButton isFav={0}>
                            <ShareIcon />
                        </ProductFavButton>
                        <ProductActionButton onClick={showProductDetailDialog}>
                            <ShoppingCartIcon color="primary" />
                        </ProductActionButton>
                    </Stack> */}
                </ProductActionWrapper>
            </Product>
            <ProductMeta product={product} matches={matches} />
            <ProductDetailDialog product={product} />
        </>
    );
}