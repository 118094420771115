import React from 'react';
import Banner from '../components/banner';
import Promotion from '../components/promotion';
import Products from '../components/products';
import { Box, Typography } from '@mui/material';

const Home = () => {
    return (
        <>
            <Banner />
            <Promotion />
            <Box display="flex" justifyContent="center" sx={{ p: 4 }}>
                <Typography variant="h4">Our Products</Typography>
            </Box>
            <Products />
        </>
    );
};

export default Home;