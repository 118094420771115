import { Divider, ListItemButton, ListItemIcon } from "@mui/material";
import { AppBarList, ActionIconsContainerMobile, ActionIconsContainerDesktop } from "../../styles/appBar";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Colors } from "../../styles/theme";
import { useNavigate } from "react-router-dom";



export default function Actions({ matches }) {
    const navigate = useNavigate();


    const Component = matches ? ActionIconsContainerMobile : ActionIconsContainerDesktop;

    return (
        <Component>
            <AppBarList type="row">
                <ListItemButton
                    sx={{
                        justifyContent: 'center',
                        width: '10%',
                    }}
                >
                    <ListItemIcon
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: matches && Colors.secondary,
                        }}
                    >
                        <ShoppingCartIcon />
                    </ListItemIcon>
                </ListItemButton>
                <Divider orientation="vertical" flexItem />
                <ListItemButton
                    sx={{
                        justifyContent: 'center',
                    }}
                >
                    <ListItemIcon
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: matches && Colors.secondary,
                        }}
                    >
                        <FavoriteBorderIcon />
                    </ListItemIcon>
                </ListItemButton>
                <Divider orientation="vertical" flexItem />
                <ListItemButton
                    sx={{
                        justifyContent: 'center',
                    }}
                >
                    <ListItemIcon
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: matches && Colors.secondary,
                        }}
                        onClick={() => {
                            navigate('/login');
                        }}
                    >
                        <PersonOutlineIcon />
                    </ListItemIcon>
                </ListItemButton>
            </AppBarList>
        </Component>
    );
}