export const ProductData = [
    {
        id: 1,
        name: "Masala Mix",
        price: 100,
        description: "A flavorful blend of aromatic Indian spices, perfect for enhancing the taste of various dishes. This versatile mix adds depth and complexity to curries, stews, and marinades.",
        image: require('../assests/products/1.png'),
    },
    {
        id: 2,
        name: "Chicken Masala",
        price: 200,
        description: "A classic Indian dish featuring tender chicken pieces simmered in a rich and flavorful masala sauce. The combination of spices like cumin, coriander, and turmeric creates a delightful aroma and enhances the taste of the chicken.",
        image: require('../assests/products/2.png'),
    },
    {
        id: 3,
        name: "Meat Masala",
        price: 300,
        description: "A flavorful blend of aromatic Indian spices, perfect for enhancing the taste of various dishes. This versatile mix adds depth and complexity to curries, stews, and marinades.",
        image: require('../assests/products/3.png'),
    },
    {
        id: 4,
        name: "Fish Masala",
        price: 400,
        description: "A flavorful blend of aromatic Indian spices, perfect for enhancing the taste of various dishes. This versatile mix adds depth and complexity to curries, stews, and marinades.",
        image: require('../assests/products/4.png'),
    },
    {
        id: 5,
        name: "Rasam Powder",
        price: 500,
        description: "A flavorful blend of aromatic Indian spices, perfect for enhancing the taste of various dishes. This versatile mix adds depth and complexity to curries, stews, and marinades.",
        image: require('../assests/products/5.png'),
    }, {
        id: 6,
        name: "Pickle Powder",
        price: 600,
        description: "A flavorful blend of aromatic Indian spices, perfect for enhancing the taste of various dishes. This versatile mix adds depth and complexity to curries, stews, and marinades.",
        image: require('../assests/products/7.png'),
    }, {
        id: 7,
        name: "Kashmiri Red Chili Powder",
        price: 700,
        description: "A flavorful blend of aromatic Indian spices, perfect for enhancing the taste of various dishes. This versatile mix adds depth and complexity to curries, stews, and marinades.",
        image: require('../assests/products/8.png'),
    }, {
        id: 8,
        name: "Chilli Powder",
        price: 800,
        description: "A flavorful blend of aromatic Indian spices, perfect for enhancing the taste of various dishes. This versatile mix adds depth and complexity to curries, stews, and marinades.",
        image: require('../assests/products/9.png'),
    }, {
        id: 9,
        name: "Turmeric Powder",
        price: 900,
        description: "A flavorful blend of aromatic Indian spices, perfect for enhancing the taste of various dishes. This versatile mix adds depth and complexity to curries, stews, and marinades.",
        image: require('../assests/products/10.png'),
    }, {
        id: 10,
        name: "Coriander Powder",
        price: 1000,
        description: "A flavorful blend of aromatic Indian spices, perfect for enhancing the taste of various dishes. This versatile mix adds depth and complexity to curries, stews, and marinades.",
        image: require('../assests/products/11.png'),
    },
];