import { Drawer, ListItemButton, ListItemText, List, Divider, lighten } from "@mui/material";
import { useUiContext } from "../../context/ui";
import { Colors } from "../../styles/theme";
import { DrawerCloseButton } from "../../styles/appBar";
import CloseIcon from '@mui/icons-material/Close';

const MidDivider = () => (
    <Divider
        sx={{
            borderColor: Colors.white,
        }}
    />
);

export default function AppDrawer() {

    const { isDrawerOpen, toggleDrawer } = useUiContext();

    return (
        <>
            {
                isDrawerOpen && (
                    <DrawerCloseButton onClick={toggleDrawer}>
                        <CloseIcon sx={{ 
                            fontSize: '2.5rem',
                            color: lighten(Colors.secondary, 0.5)
                         }} />
                    </DrawerCloseButton>
                )
            }

            <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={toggleDrawer}
            >
                <List>
                    <ListItemButton>
                        <ListItemText primary="Home" />
                    </ListItemButton>
                    <MidDivider />
                    <ListItemButton>
                        <ListItemText primary="Products" />
                    </ListItemButton>
                    <MidDivider />
                    <ListItemButton>
                        <ListItemText primary="About Us" />
                    </ListItemButton>
                    <MidDivider />
                    <ListItemButton>
                        <ListItemText primary="Contact" />
                    </ListItemButton>
                </List>
            </Drawer>
        </>
    );
}