import { Box, Button, Grid2, List, ListItemText, Stack, Typography } from "@mui/material";
import { FooterTitle, SubscribeTextField } from "../../styles/footer";
import { Colors } from "../../styles/theme";

// Icons
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SendIcon from '@mui/icons-material/Send';


export default function Footer() {
    return (
        <Box
            sx={{
                backgroundColor: Colors.primary,
                color: Colors.white,
                p: { xs: 4, md: 10 },
                pt: 12,
                pb: 12,
                fontSize: { xs: '12px', md: '14px' },
            }}
        >
            <Grid2 container spacing={2} justifyContent="center">
                <Grid2 item md={6} lg={4}>
                    <FooterTitle>
                        About Us
                    </FooterTitle>
                    <Typography>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Quisquam, quos.
                    </Typography>
                    <Box
                        sx={{
                            mt: 4,
                            color: Colors.dove_gray,
                        }}
                    >
                        <FacebookIcon />
                        <InstagramIcon />
                        <TwitterIcon />
                        <LinkedInIcon />
                    </Box>
                </Grid2>
                <Grid2 item md={6} lg={4}>
                    <FooterTitle>
                        Informations
                    </FooterTitle>
                    <List>
                        <ListItemText>
                            <Typography lineHeight={2} variant="caption">
                                About Us
                            </Typography>
                        </ListItemText>
                        <ListItemText>
                            <Typography lineHeight={2} variant="caption">
                                Delivery Information
                            </Typography>
                        </ListItemText>
                        <ListItemText>
                            <Typography lineHeight={2} variant="caption">
                                Privacy Policy
                            </Typography>
                        </ListItemText>
                        <ListItemText>
                            <Typography lineHeight={2} variant="caption">
                                Terms & Conditions
                            </Typography>
                        </ListItemText>
                    </List>
                </Grid2>
                <Grid2 item md={6} lg={2}>
                    <FooterTitle>
                        My Account
                    </FooterTitle>
                    <List>
                        <ListItemText>
                            <Typography lineHeight={2} variant="caption">
                                Login
                            </Typography>
                        </ListItemText>
                        <ListItemText>
                            <Typography lineHeight={2} variant="caption">
                                My Cart
                            </Typography>
                        </ListItemText>
                        <ListItemText>
                            <Typography lineHeight={2} variant="caption">
                                Order History
                            </Typography>
                        </ListItemText>
                    </List>
                </Grid2>
                <Grid2 item md={6} lg={4}>
                    <FooterTitle>
                        Newsletter
                    </FooterTitle>
                    <Stack direction="row" spacing={2}>
                        <SubscribeTextField 
                            color="secondary"
                            label="Email Address"
                            variant="standard"
                        />
                        <Button
                            startIcon={<SendIcon sx={{ color: Colors.white }} />}
                            sx={{
                                mt: 4,
                                mb: 4,
                                backgroundColor: Colors.secondary,
                                '&:hover': {
                                    backgroundColor: Colors.secondary,
                                },
                            }}
                            variant="contained"
                        >
                            Subscribe
                        </Button>
                    </Stack>
                </Grid2>
            </Grid2>
        </Box>
    );
}