import React, { useState, useEffect } from 'react';
import { Box, Container, Grid2, Typography, List, ListItem, ListItemText, Checkbox, Slider, Card, CardContent, CardMedia, Button } from '@mui/material';

const Products = () => {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [priceRange, setPriceRange] = useState([0, 1000]);

    useEffect(() => {
        // Fetch products and categories from your API
        // This is a placeholder. Replace with actual API calls
        setProducts([
            { id: 1, name: 'Product 1', category: 'Electronics', price: 299, image: '/images/product1.jpg' },
            { id: 2, name: 'Product 2', category: 'Clothing', price: 59, image: '/images/product2.jpg' },
            { id: 3, name: 'Product 3', category: 'Books', price: 19, image: '/images/product3.jpg' },
            { id: 4, name: 'Product 4', category: 'Home & Garden', price: 79, image: '/images/product4.jpg' },
            { id: 5, name: 'Product 5', category: 'Electronics', price: 299, image: '/images/product5.jpg' },
            { id: 7, name: 'Product 7', category: 'Books', price: 19, image: '/images/product7.jpg' },
            // Add more products...
        ]);
        setCategories(['Electronics', 'Clothing', 'Books', 'Home & Garden']);
    }, []);

    const handleCategoryChange = (category) => {
        setSelectedCategories(prev => 
            prev.includes(category)
                ? prev.filter(c => c !== category)
                : [...prev, category]
        );
    };

    const handlePriceChange = (event, newValue) => {
        setPriceRange(newValue);
    };

    const filteredProducts = products.filter(product => 
        (selectedCategories.length === 0 || selectedCategories.includes(product.category)) &&
        (product.price >= priceRange[0] && product.price <= priceRange[1])
    );

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid2 container spacing={3}>
                <Grid2 item xs={12} md={3}>
                    <Typography variant="h6" gutterBottom>
                        Filters
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Categories
                    </Typography>
                    <List>
                        {categories.map((category) => (
                            <ListItem key={category} dense button onClick={() => handleCategoryChange(category)}>
                                <Checkbox
                                    edge="start"
                                    checked={selectedCategories.includes(category)}
                                    tabIndex={-1}
                                    disableRipple
                                />
                                <ListItemText primary={category} />
                            </ListItem>
                        ))}
                    </List>
                    <Typography variant="subtitle1" gutterBottom>
                        Price Range
                    </Typography>
                    <Box sx={{ width: 200 }}>
                        <Slider
                            value={priceRange}
                            onChange={handlePriceChange}
                            valueLabelDisplay="auto"
                            min={0}
                            max={1000}
                        />
                        <Typography>
                            ${priceRange[0]} - ${priceRange[1]}
                        </Typography>
                    </Box>
                </Grid2>
                <Grid2 item xs={12} md={9}>
                    <Typography variant="h4" gutterBottom>
                        Products
                    </Typography>
                    <Grid2 container spacing={2}>
                        {filteredProducts.map((product) => (
                            <Grid2 item xs={12} sm={6} md={4} key={product.id}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={product.image}
                                        alt={product.name}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="div">
                                            {product.name}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            ${product.price}
                                        </Typography>
                                        <Button size="small" color="primary">
                                            Add to Cart
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid2>
                        ))}
                    </Grid2>
                </Grid2>
            </Grid2>
        </Container>
    );
};

export default Products;
