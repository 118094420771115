import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const BannerImage = styled('img')({
    width: '100%',
    height: '300px',
    objectFit: 'cover',
});

const About = () => {
    return (
        <Box>
            <BannerImage src="/images/about-banner.jpg" alt="About Us Banner" />
            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <Typography variant="h4" gutterBottom>
                    About Unicorn
                </Typography>
                <Typography variant="body1" paragraph>
                    Unicorn is a cutting-edge e-commerce platform dedicated to bringing you the most innovative and unique products from around the world. Founded in 2023, our mission is to inspire creativity and enhance everyday life through carefully curated selections.
                </Typography>
                <Typography variant="body1" paragraph>
                    At Unicorn, we believe in the power of imagination and the joy of discovery. Our team of passionate experts scours the globe to find products that are not just functional, but also beautiful, sustainable, and often conversation starters. From tech gadgets to home decor, fashion accessories to gourmet treats, we offer a diverse range of items that cater to the curious and the creative.
                </Typography>
                <Typography variant="body1">
                    Join us on this exciting journey as we continue to grow, innovate, and bring magic to your everyday life. Because at Unicorn, we don't just sell products – we deliver experiences.
                </Typography>
            </Container>
        </Box>
    );
};

export default About;
