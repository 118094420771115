import { Stack } from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// Styles
import { Product, ProductActionButton, ProductActionWrapper, ProductAddToCart, ProductFavButton, ProductImage } from "../../styles/product";

// Components
import ProductMeta from "./productMeta";
import ProductDetail from "../productDetail";
// Hooks
import useDialogModal from "../../hooks/useDialogModal";

export default function SingleProduct({ product, matches }) {

    const [ProductDetailDialog, showProductDetailDialog] = useDialogModal(ProductDetail);

    return (
        <>
            <Product>
                <ProductImage src={product.image} alt={product.name} />
                <ProductMeta product={product} matches={matches} />
                <ProductActionWrapper show={true}>
                    <Stack direction="column">
                        <ProductFavButton isFav={1}>
                            <FavoriteBorderIcon />
                        </ProductFavButton>
                        <ProductActionButton onClick={showProductDetailDialog}>
                            <ShoppingCartIcon color="primary"  />
                        </ProductActionButton>
                    </Stack>
                </ProductActionWrapper>
            </Product>
            <ProductAddToCart variant="contained">Add to cart</ProductAddToCart>
            <ProductDetailDialog product={product} />
        </>
    );
}