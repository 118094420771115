import { Box, IconButton, Typography } from "@mui/material";
import { clamp } from "./clamp";
import { useState } from "react";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';


export default  function IncDec() {
    const clampValue = clamp(1, 100, 10);
    const [value, setValue] = useState(10);

    return (
        <Box display={'flex'}>
            <IconButton
                sx={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    backgroundColor: 'primary.main',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: 'primary.dark',
                    }
                }}
                onClick={() => setValue(clampValue(value - 1))}
            >
                <RemoveIcon />
            </IconButton>
            <Typography>{value}</Typography>
            <IconButton
                sx={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    backgroundColor: 'primary.main',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: 'primary.dark',
                    }
                }}
                onClick={() => setValue(clampValue(value + 1))}
            >
                <AddIcon />
            </IconButton>
        </Box>
    )
}
