import { lighten } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

export const Colors = {
    primary: '#16423C',
    secondary: '#6A9C89',
    tertiary: '#C4DAD2',
    quaternary: '#E9EFEC',
    // Keeping the remaining colors as fallbacks or for additional use if needed
    quinary: '#d0d0d0',
    senary: '#c0c0c0',
    septenary: '#b0b0b0',
    octonary: '#a0a0a0',
    shaft: '#333',  // Added shaft color
    border: '#e0e0e0',  // Added border color
    light_gray: '#f0f0f0',
    white: '#fff',
    dove_gray: '#696a6a',
};

export const theme = createTheme({
    palette: {
        primary: {
            main: Colors.primary,
        },
        secondary: {
            main: Colors.secondary,
        },
    },

    components: {
        MuiButton: {
            defaultProps: {
                disableRipple: true,
                disableElevation: true,
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    width: 250,
                    background: Colors.primary,
                    color: Colors.secondary,
                    borderRadius: '0px 100px 0px 0px',
                    borderRight: `1px solid ${Colors.secondary}`,
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: lighten(Colors.secondary, 0.5),
                },
            },
        },
        MyShopButton: {
            styleOverrides: {
                root: {
                    color: Colors.white,
                },
                primary: {
                    background: Colors.primary,
                    "&:hover": {
                        background: lighten(Colors.primary, 0.05),
                    },
                },
                secondary: {
                    background: Colors.secondary,
                    "&:hover": {
                        background: lighten(Colors.secondary, 0.05),
                    },
                },
            },
        },
    },
});