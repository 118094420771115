import { Button, styled, Typography } from "@mui/material";
import { Box } from "@mui/material";

// Styles
import { Colors } from "../theme";


export const BannerContainer = styled(Box)(({ theme, src }) => ({
    display: 'flex',
    // justifyContent: 'center',
    height: '100%',
    width: '100%',
    padding: '0px 0px',
    // backgroundColor: Colors.light_gray,
    backgroundImage: `url(${src})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
    },
}));

export const BannerContent = styled(Box)(({ theme }) => ({

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 520,
    padding: '30px',
}));

export const BannerImage = styled('img')(({ src, theme }) => ({
    src: `url(${src})`,
    width: '500px',
    [theme.breakpoints.down('md')]: {
        width: '350px'
    },

    [theme.breakpoints.up('sm')]: {
        width: '320px',
        height: '300px',
    },
}));

export const BannerTitle = styled(Typography)(({ theme }) => ({

    lineHeight: 1.5,
    fontSize: '72px',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
        fontSize: '42px',
    },
}));

export const BannerDescription = styled(Typography)(({ theme }) => ({

    lineHeight: 1.25,
    letterSpacing: 1.25,
    marginBottom: '3em',
    [theme.breakpoints.down('md')]: {
        lineHeight: 1.15,
        letterSpacing: 1.15,
        marginBottom: '1.5em',
    }
}));

export const BannerShopButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'color',
    name: 'BannerShopButton',
    slot: 'Root',
    overridesResolver: (props, styles) => [
        styles.root,
        props.color === 'secondary' && styles.secondary,
        props.color === 'primary' && styles.primary,
    ],
})(({ theme, color }) => ({
    backgroundColor: Colors.primary,
    padding: '20px 0px',
    color: Colors.white,
    fontWeight: 'bold',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
        padding: '10px 0px',
        fontSize: 12,
    },
}));